import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UploadState {
  url: string;
  uploadPercentage: number;
  openProgressModal: boolean;
}

const initialState: UploadState = { url: "", uploadPercentage: 0, openProgressModal: false };

export const uploadFileStateSlice = createSlice({
  name: "uploadFileState",
  initialState,
  reducers: {
    startUploading: (state, action: PayloadAction<{ url: string }>) => {
      state.url = action.payload.url;
      state.openProgressModal = true;
      state.uploadPercentage = 0;
    },
    deleteCompletedUploadFile: (state) => {
      state.url = "";
      state.openProgressModal = false;
      state.uploadPercentage = 0;
    },
    updateProgressBar: (state, action: PayloadAction<{ url: string; progress: number }>) => {
      state.url = action.payload.url;
      state.openProgressModal = true;
      state.uploadPercentage = action.payload.progress;
    }
  }
});

export const { updateProgressBar, startUploading, deleteCompletedUploadFile } = uploadFileStateSlice.actions;

export default uploadFileStateSlice.reducer;
