import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateTokenType, userCredsType } from "../types/userLoginTypes";

export interface userLoginState {
  token: string | null;
  email: string;
  password: string;
}

const initialState: userLoginState = {
  token: null,
  email: "",
  password: "string"
};

export const userLoginStateSlice = createSlice({
  name: "userLoginState",
  initialState,
  reducers: {
    updateToken: (state: { token: any }, action: PayloadAction<updateTokenType>) => {
      state.token = action.payload.token;
    },
    saveCreds: (state: { email: any; password: any }, action: PayloadAction<userCredsType>) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
    }
  }
});

export const { updateToken, saveCreds } = userLoginStateSlice.actions;

export default userLoginStateSlice.reducer;
