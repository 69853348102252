const routeConstants = {
  DashBoard: "/dashboard",
  PleaseWait: "/pleaseWait",
  SoftwareLicensesList: "/licenses",
  SoftwareLicensesAdd: "/licenses/add",
  SoftwareLicensesDetail: "/licenses/detail/:id",
  SoftwareLicenseAssign: "/licenses/assign/:id",
  SoftwareLicenseAssignConfirm: "/licenses/assignConfirmation/:id",
  SoftwareLabelList: "/versions",
  SoftwareLabelAdd: "/versions/add",
  SoftwareLabelDetail: "/versions/detail/:id",
  SystemLog: "/systemLog",
  Organizations: "/organizations",
  OrganizationsAdd: "/organizations/add",
  OrgDetailPage: "/organizations/detail/:id",
  UserDetailPage: "/user/detail/:id",
  SoftwareManager: "/softwareManager",
  SoftwareManagerAdd: "/softwareManager/add",
  OrganizationAccountBalance: "/organizationBalance/:id",
  ManageOrganizationBalance: "/manageOrganizationBalance/:id",
  SoftwareManagerDetail: "/softwareManager/detail/:id",
  SystemUsers: "/systemUsers",
  SystemUserDetail: "/systemUser/detail/:id",
  SoftwareManagerAssign: "/softwareManager/assign/:id",
  ManageOrganizationUser: "/manageOrganizationUser/:id",
  AddUserToOrganization: "/organization/addUser/:id",
  SoftwareManagerAssignConfirmation: "/softwareManager/assignConfirmation/:id",
  AdminUsers: "/adminUsers",
  AdminUserDetail: "/adminUsers/detail/:id",
  AdminUserAdd: "/adminUsers/add/",
  AdminUserRoleChange: "/adminUsers/roleChange/:id",
  AdminUserOrgChange: "/adminUsers/orgChange",
  CurrentUserProfile: "/currentUser/profile",
  CurrentUserTwoFactorAuth: "/currentUser/twoFactorAuth",
  CurrentUserTwoFactorAuthEnablePage: "/currentUser/twoFactorAuthEnable",
  CurrentUserTwoFactorAuthDisablePage: "/currentUser/twoFactorAuthDisable",
  CurrentUserChangePassword: "/currentUser/changePassword",
  CreateCpuBalance: "/createCpuBalance",
  ManageMachines: "/manageMachines",
  CurrentUserEditProfile: "/currentUser/editProfile",
  LoginPage: "/login",
  Login2FaPage: "/login2Fa",
  Login2FaRecoveryCode: "/login2FaRecoveryCode",
  Runs: "/simulationRuns",
  RunDetails: "/simulationRuns/details/:id",
  SystemUserRuns: "/systemUser/runs/:id",
  AccountingPage: "/accounting"
};

const replaceRouteParam = (route: string, paramName: string, value: string): string => {
  const routeWithParam = route.replace(paramName, value);
  return routeWithParam;
};

const replaceIdRouteParam = (route: string, value: string): string => {
  return replaceRouteParam(route, ":id", value);
};

export { replaceRouteParam, replaceIdRouteParam };
export default routeConstants;
