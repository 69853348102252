import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";

export interface softwareVersionTextInputCompState {
  softwareVersion: string;
  error: string;
}

const initialState: softwareVersionTextInputCompState = {
  softwareVersion: "",
  error: ""
};
export const softwareVersionTextInputCompStateSlice = createSlice({
  name: "softwareVersionTextInputCompState",
  initialState,
  reducers: {
    softwareVersionTextInputCompReset: (state) => {
      state.softwareVersion = "";
      state.error = "";
    },
    softwareVersionTextInputCompUpdate: (state, action: PayloadAction<updateStringValueType>) => {
      state.softwareVersion = action.payload.value;
    },
    softwareVersionTextInputCompSetError: (state, action: PayloadAction<updateStringValueType>) => {
      state.error = action.payload.value;
    }
  }
});

export const { softwareVersionTextInputCompReset, softwareVersionTextInputCompUpdate, softwareVersionTextInputCompSetError } =
  softwareVersionTextInputCompStateSlice.actions;

export default softwareVersionTextInputCompStateSlice.reducer;
