import React, { useState } from "react";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";

import LoginTextInput from "./LoginTextInput";

type ErrorMessage = {
  message: string;
};

type LoginFormProps = {
  handleSubmit: Function;
  errors: ErrorMessage[];
  loading: boolean;
};

/**
 * Loading parameter indicates that the process to login is being executed
 * once the request is completed loading is set to false
 * @param {boolean} loading
 */
const LoginForm = ({ handleSubmit, errors, loading }: LoginFormProps) => {
  let errorContent;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  if (errors.length > 0) {
    errorContent = <div className="alert alert-danger">{errors[0].message}</div>;
  }

  return (
    <>
      <Card className="p-4">
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e, { email, password })}>
            {errorContent}
            <h1>Login</h1>
            <p className="text-muted">Sign In to your account</p>

            <LoginTextInput
              groupClass="mb-3"
              required
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="email"
              onChange={handleEmailChange}
              icon="fa fa-at"
              data-test-id="login-form-email"
            />

            <LoginTextInput
              groupClass="mb-4"
              required
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              icon="fa fa-key"
              data-test-id="login-form-password"
            />

            <Row>
              <Col xs="6">
                <Button type="submit" color="primary" className="px-4" data-test-id="login-form-submit">
                  {loading && (
                    <div style={{ display: "inline" }}>
                      <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                    </div>
                  )}
                  &nbsp;&nbsp;Login
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default LoginForm;
