import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AXIOS_INSTANCE } from "../api/mutator/custom-instance";
import addRefreshTokenInterceptor from "../api/mutator/refreshTokenInterceptor";
import { TOKEN_STORAGE_KEY } from "../constants/localStorage";
import { useAppSelector } from "../redux/reducerConfig";

type Dispatch = (Auth: string) => void;

type AuthProviderProps = { children: ReactNode; initialState?: string | null };

const AuthContext = createContext<string | null>(null);

function AuthProvider({ children, initialState = null }: AuthProviderProps) {
  const stateVals = useAppSelector((state) => state.DaUiReducer.userLoginState);
  const token = stateVals.token;

  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use((config: any) => ({
      ...config,
      headers: token
        ? {
            ...config.headers,
            Authorization: `Bearer ${token}`
          }
        : config.headers
    }));

    // add refresh token interceptor
    addRefreshTokenInterceptor();

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [token]);

  return <AuthContext.Provider value={token}>{children}</AuthContext.Provider>;
}

/**
 * Use Auth context hook
 * @returns
 */
const useAuth = (): string | null => useContext<string | null>(AuthContext);

export { AuthProvider, useAuth };
