import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";

export interface simulationSoftwareNamesSelectorCompState {
  selectedName: string;
  error: string;
}

const initialState: simulationSoftwareNamesSelectorCompState = {
  selectedName: "",
  error: ""
};
export const simulationSoftwareNamesSelectorCompStateSlice = createSlice({
  name: "simulationSoftwareNamesSelectorCompState",
  initialState,
  reducers: {
    resetSelectedSoftwareName: (state) => {
      state.selectedName = "";
      state.error = "";
    },
    updateSelectedSoftwareName: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedName = action.payload.value;
    },
    setSelectedSoftwareNameError: (state, action: PayloadAction<updateStringValueType>) => {
      state.error = action.payload.value;
    }
  }
});

export const { resetSelectedSoftwareName, updateSelectedSoftwareName, setSelectedSoftwareNameError } =
  simulationSoftwareNamesSelectorCompStateSlice.actions;

export default simulationSoftwareNamesSelectorCompStateSlice.reducer;
