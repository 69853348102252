import React, { FunctionComponent } from "react";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { InputType } from "reactstrap/es/Input";
type TextInputProps = {
  name?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  placeholder?: string;
  value?: string;
  error?: string;
  required?: boolean;
  type?: InputType;
  maxLength?: number;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
};

const TextInput: FunctionComponent<TextInputProps> = ({ name, label, onChange, onKeyPress, error, type = "text", maxLength = -1, ...props }) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  const onKeyPressInternal: React.KeyboardEventHandler<HTMLInputElement> = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // disabling enter button
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }

    maxLengthCheck(event);

    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const maxLengthCheck = (object: any) => {
    if (object.target.maxLength > 0) {
      if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(0, object.target.maxLength);
      }
    }
  };

  return (
    <Row className="text-input">
      <Col xs="12">
        <FormGroup className={wrapperClass}>
          {label && <Label htmlFor={name}>{label}</Label>}
          <Input onKeyPress={onKeyPressInternal} onChange={onChange} type={type} maxLength={maxLength} id={name} invalid={!!error} {...props} />
          {error && <FormFeedback>{error}</FormFeedback>}
        </FormGroup>
      </Col>
    </Row>
  );
};
export default TextInput;
