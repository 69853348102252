import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";

export interface licenseAssignmentConfirmationCompState {
  selectedLicenseId: string | undefined;
  selectedOrgId: string | undefined;
}

const initialState: licenseAssignmentConfirmationCompState = {
  selectedLicenseId: undefined,
  selectedOrgId: undefined
};
export const licenseAssignmentConfirmationCompStateSlice = createSlice({
  name: "softwareAssignmentConfirmationCompState",
  initialState,
  reducers: {
    licenseAssignmentConfirmationCompReset: (state) => {
      state.selectedLicenseId = undefined;
      state.selectedOrgId = undefined;
    },
    licenseAssignmentSetSoftwareId: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedLicenseId = action.payload.value;
    },
    licenseAssignmentSetOrgId: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedOrgId = action.payload.value;
    }
  }
});

export const { licenseAssignmentConfirmationCompReset, licenseAssignmentSetSoftwareId, licenseAssignmentSetOrgId } =
  licenseAssignmentConfirmationCompStateSlice.actions;

export default licenseAssignmentConfirmationCompStateSlice.reducer;
