import { AxiosError } from "axios";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Row } from "reactstrap";
import { authControllerLogin } from "../../api/endpoints/auth-controller";
import { AuthControllerLoginBody } from "../../api/endpoints/ksApi.schemas";
import routeConstants from "../../appBase/routeConstants";
import { REFRESH_TOKEN_STORAGE_KEY, ROLE_LEVEL_STORAGE_KEY, TOKEN_STORAGE_KEY } from "../../constants/localStorage";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/reducerConfig";
import { updateToken } from "../../redux/reducers/userLoginStateReducer";
import NumberInput from "../common/form/NumberInput";
import TwoFactorAuthDisablePage from "../currentUser/2FA/authDisable/TwoFactorAuthDisablePage";

const Login2FaPage = () => {
  const history = useHistory();
  const [hasFailed2Fa, setHasFailed2Fa] = useState(false);
  const [token, setToken] = useState("");
  const [processing, setProcessing] = useState(false);
  const [tokenError, setTokenError] = useState("");
  const stateVals = useAppSelector((state) => state.DaUiReducer.userLoginState);

  const appDispatch = useAppDispatch();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setProcessing(true);

    if (token.length < 6) {
      setTokenError("The code you entered is too short. Please enter 6 digits");
      setProcessing(false);
      return;
    }

    const loginData: AuthControllerLoginBody = { email: stateVals.email, password: stateVals.password, twoFactorAuthenticationCode: token };

    try {
      const resp = await authControllerLogin(loginData);
      if (resp.token != null && resp.refreshToken != null && resp.roleLevel != null) {
        setProcessing(false);
        localStorage.setItem(TOKEN_STORAGE_KEY, resp.token);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, resp.refreshToken);
        localStorage.setItem(ROLE_LEVEL_STORAGE_KEY, resp.roleLevel.toString());

        appDispatch(updateToken({ token: resp.token }));

        history.push(routeConstants.DashBoard);
      } else {
        throw new Error("Incorrect login");
      }
    } catch (e) {
      setToken("");
      const error = e as AxiosError;
      // @ts-ignore
      const msg = error?.response?.data.error.message as string;
      setTokenError(msg);
    }
    setProcessing(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value);
    setTokenError("");
  };

  const handleCancel = async () => {
    history.push(routeConstants.LoginPage);
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="9" lg="7" xl="6">
            <Form>
              <Card className="mx-4">
                <CardHeader className="text-white bg-info">Two factor authentication </CardHeader>
                <CardBody>
                  <h5>
                    Your account is configured to use two factor authentication.
                    <br /> <br />
                    Please enter your 6-digit authentication code below.
                  </h5>
                  <NumberInput value={token} error={tokenError} onChange={onChange} maxLength={6} placeholder="Code" />
                  <br />
                  If you are unable to access your authentication app, you can use your recovery code by clicking the link below.
                  <br />
                  <Link to={routeConstants.Login2FaRecoveryCode}>Login with recovery code</Link>
                </CardBody>
                <CardFooter className="p-2">
                  <Row>
                    <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button color="secondary" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button className="ml-2" onClick={async (e) => await handleSubmit(e)} disabled={processing} color="primary">
                        Submit code
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login2FaPage;
