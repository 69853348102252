import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tableTypes } from "../../constants/tableTypes";

export interface TableState {
  [key: string]: {
    textFilter: string;
    fieldFilter: string;
    dateFilter: {
      startDate: string;
      endDate: string;
    };
  };
}

const initialState: TableState = {
  [tableTypes.SystemLogsPage]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.SYSTEM_USER_TABLE]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.ORGANIZATION_LIST_TABLE]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.ADMIN_USERS_TABLE]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.SOFTWARE_LICENSES_TABLE]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.CUSTOM_SOFTWARE_TABLE]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.SoftwareLabelsTable]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.RunsListTable]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  },
  [tableTypes.AccountingTable]: {
    textFilter: "",
    fieldFilter: "",
    dateFilter: {
      startDate: "",
      endDate: ""
    }
  }
};

export const tableStateSlice = createSlice({
  name: "tableState",
  initialState,
  reducers: {
    updateTableFilter: (state, action: PayloadAction<{ textFilter: string; tableName: string }>) => {
      state[action.payload.tableName].textFilter = action.payload.textFilter;
    },
    updateTableFieldFilter: (state, action: PayloadAction<{ fieldFilter: string; tableName: string }>) => {
      state[action.payload.tableName].fieldFilter = action.payload.fieldFilter;
    },
    addNewTable: (state, action: PayloadAction<{ tableFilter: string; tableName: string }>) => {
      state[action.payload.tableName].textFilter = action.payload.tableFilter;
    },
    setStartDateFilter: (state, action: PayloadAction<{ startDate: Date | null; tableName: string }>) => {
      state[action.payload.tableName].dateFilter.startDate = action.payload.startDate ? action.payload.startDate.toISOString() : "";
    },
    setEndDateFilter: (state, action: PayloadAction<{ endDate: Date | null; tableName: string }>) => {
      state[action.payload.tableName].dateFilter.endDate = action.payload.endDate ? action.payload.endDate.toISOString() : "";
    },
    resetFilter: (state, action: PayloadAction<{ tableName: string }>) => {
      state[action.payload.tableName].textFilter = "";
      state[action.payload.tableName].dateFilter.endDate = "";
      state[action.payload.tableName].dateFilter.startDate = "";
    }
  }
});

export const { updateTableFilter, resetFilter, addNewTable, setStartDateFilter, setEndDateFilter, updateTableFieldFilter } =
  tableStateSlice.actions;

export default tableStateSlice.reducer;
