import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import uiConfig from "react-global-configuration";

export const AXIOS_INSTANCE = Axios.create({ baseURL: uiConfig.get("apiDomainBaseURL") });

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();

  const baseUrl: string = uiConfig.get("apiDomainBaseURL");
  // get the baseUrl from the config
  AXIOS_INSTANCE.defaults.baseURL = baseUrl.startsWith("http") ? baseUrl : `https://${baseUrl}/`;
  // DJS todo add rate limiting here!!

  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}
