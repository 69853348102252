import React, { Component, Suspense, useEffect } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";

import "./scss/style.scss";

// Applying this style might break the layout
import "./App.css";

import "react-datepicker/dist/react-datepicker.css";

import PrivateRoute from "./appBase/PrivateRoute";
import routeConstants from "./appBase/routeConstants";
import LoginPage from "./components/login/LoginPage";
import Login2FaPage from "./components/login/Login2FaPage";
import Login2FaRecoveryCode from "./components/login/Login2FaRecoveryCode";
import Logout from "./appBase/Logout";
import NotFound404 from "./components/common/NotFound404";
import { useAppDispatch } from "./redux/reducerConfig";
import { TOKEN_STORAGE_KEY } from "./constants/localStorage";
import { updateToken } from "./redux/reducers/userLoginStateReducer";
import { kccConfigControllerGetKccConfig } from "./api/endpoints/kcc-config-controller";
import { setKccConfigApiUrl } from "./config/config";

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const loadingSpinner = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// absolute URL of the page
const host = `${window.location.protocol}//${window.location.host}`;

function App() {
  const appDispatch = useAppDispatch();
  useEffect(() => {
    // load token from local storage if it exists
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (token != null) {
      appDispatch(updateToken({ token: token }));
    }
  }, []);

  useEffect(() => {
    const loadKccConfig = async () => {
      const resp: any = await kccConfigControllerGetKccConfig({ host: host });

      if (resp?.domainApiUrl) {
        setKccConfigApiUrl(resp.domainApiUrl);
      }
    };
    loadKccConfig();
  }, [host]);

  return (
    <BrowserRouter>
      <Suspense fallback={loadingSpinner()}>
        <Switch>
          <Route path={routeConstants.LoginPage} exact component={LoginPage} />
          <Route path={routeConstants.Login2FaPage} exact component={Login2FaPage} />
          <Route path={routeConstants.Login2FaRecoveryCode} exact component={Login2FaRecoveryCode} />
          <Route path="/logout" exact component={Logout} />
          <PrivateRoute path="/" component={DefaultLayout} />
          <Route path="*" component={NotFound404} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
