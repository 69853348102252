import { ChangeEventHandler } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { InputType } from "reactstrap/es/Input";

type LoginTextInputProps = {
  groupClass: string;
  icon: string;
  required: boolean;
  type: InputType;
  name: string;
  placeholder: string;
  autoComplete: string;
  onChange: ChangeEventHandler;
  "data-test-id": string;
};

const LoginTextInput = ({ groupClass, icon, ...props }: LoginTextInputProps) => {
  return (
    <>
      <InputGroup className={groupClass}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input {...props} />
      </InputGroup>
    </>
  );
};

export default LoginTextInput;
