/**
 * String used to identify the different tables
 * in our redux store
 */
export const tableTypes = {
  SystemLogsPage: "SystemLogsPage",
  SYSTEM_USER_TABLE: "SystemUserTable",
  ORGANIZATION_LIST_TABLE: "OrganizationListTable",
  ADMIN_USERS_TABLE: "AdminUsersTable",
  SOFTWARE_LICENSES_TABLE: "SoftwareLicensesTable",
  SoftwareLabelsTable: "SoftwareLabelsTable",
  CUSTOM_SOFTWARE_TABLE: "CustomSoftwareTable",
  RunsListTable: "RunsListTable",
  AccountingTable: "AccountingTable"
};
