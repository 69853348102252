import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";

// softwareLabelSelectorFormComp
export interface softwareLabelSelectorFormCompState {
  selectedLabel: string;
  error: string;
}

const initialState: softwareLabelSelectorFormCompState = {
  selectedLabel: "",
  error: ""
};
export const softwareLabelSelectorFormCompStateSlice = createSlice({
  name: "softwareLabelSelectorFormCompState",
  initialState,
  reducers: {
    resetSelectedLabel: (state) => {
      state.selectedLabel = "";
      state.error = "";
    },
    updateSelectedLabel: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedLabel = action.payload.value;
    },
    setSelectedLabelError: (state, action: PayloadAction<updateStringValueType>) => {
      state.error = action.payload.value;
    }
  }
});

export const { resetSelectedLabel, updateSelectedLabel, setSelectedLabelError } = softwareLabelSelectorFormCompStateSlice.actions;

export default softwareLabelSelectorFormCompStateSlice.reducer;
