import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import machineImageSelectorFormCompReducer from "./reducers/machineImageSelectorFormCompReducer";
import simulationSoftwareNamesSelectorCompReducer from "./reducers/simulationSoftwareNamesSelectorCompReducer";
import uploadFileDialogReducer from "./reducers/uploadFileDialogReducer";
import uploadSoftwareCompReducer from "./reducers/uploadSoftwareCompReducer";
import userLoginStateReducer from "./reducers/userLoginStateReducer";
import softwareVersionTextInputCompStateReducer from "./reducers/softwareVersionTextInputCompReducer";
import softwareAssignmentConfirmationCompReducer from "./reducers/softwareAssignmentConfirmationCompReducer";
import LicenseAssignmentConfirmationCompReducer from "./reducers/LicenseAssignmentConfirmationCompReducer";
import tableStateReducer from "./reducers/tableStateReducer";
import softwareLabelSelectorFormCompReducer from "./reducers/softwareLabelSelectorFormCompReducer";
import uploadFileStateReducer from "./reducers/uploadFileStateReducer";
import layoutReducer from "./reducers/layoutReducer";

// Combine our reducers
const combinedReducers = combineReducers({
  userLoginState: userLoginStateReducer,
  uploadFileDialogState: uploadFileDialogReducer,
  machineImageSelectorFormCompState: machineImageSelectorFormCompReducer,
  softwareLabelSelectorFormCompState: softwareLabelSelectorFormCompReducer,
  simulationSoftwareNamesSelectorCompState: simulationSoftwareNamesSelectorCompReducer,
  uploadSoftwareCompState: uploadSoftwareCompReducer,
  softwareVersionTextInputCompState: softwareVersionTextInputCompStateReducer,
  softwareAssignmentConfirmationCompState: softwareAssignmentConfirmationCompReducer,
  licenseAssignmentConfirmationCompState: LicenseAssignmentConfirmationCompReducer,
  tableState: tableStateReducer,
  uploadFileState: uploadFileStateReducer,
  layoutState: layoutReducer
});

// Create our store
export const store = configureStore({
  reducer: { DaUiReducer: combinedReducers }
});

export type RootState = ReturnType<typeof store.getState>;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
