import React, { Route, Redirect, RouteProps } from "react-router-dom";
import { REFRESH_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from "../constants/localStorage";

function Logout() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

  /*
  client.resetStore();
  client.cache.reset();

  localStorage.removeItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);

  const enableTwoFA: string = config.get("enableTwoFA");
  if (enableTwoFA) {
    localStorage.setItem(TWO_FA_CHECK, "disable");
  }
  */

  return <Redirect to="/" />;
}

export default Logout;
