import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";
import { updateSelectedFilesType } from "../types/uploadSoftwareCompTypes";

export interface uploadSoftwareCompState {
  hasFiles: boolean;
  error: string;
}

const initialState: uploadSoftwareCompState = {
  hasFiles: false,
  error: ""
};

export const uploadSoftwareCompStateSlice = createSlice({
  name: "uploadSoftwareCompState",
  initialState,
  reducers: {
    resetUploadSoftwareComp: (state: { hasFiles: boolean; error: string }) => {
      state.hasFiles = false;
      state.error = "";
    },
    uploadSoftwareCompSetSelectedFiles: (state: { hasFiles: any; error: string }, action: PayloadAction<updateSelectedFilesType>) => {
      state.hasFiles = action.payload.hasFiles;
      state.error = "";
    },
    uploadSoftwareCompSetError: (state: { error: any }, action: PayloadAction<updateStringValueType>) => {
      state.error = action.payload.value;
    }
  }
});

export const { resetUploadSoftwareComp, uploadSoftwareCompSetSelectedFiles, uploadSoftwareCompSetError } = uploadSoftwareCompStateSlice.actions;

export default uploadSoftwareCompStateSlice.reducer;
