import config from "react-global-configuration";
import { uiEnv } from "./env";

const environment = process.env.REACT_APP_ENV || "development";

if (environment in uiEnv) {
  const envConfig = uiEnv[environment];

  config.set(
    {
      ...envConfig
    },
    { freeze: false }
  );
} else {
  throw new Error("Invalid config");
}

/**
 * Add the KCC domain api url to the config
 * @param {String} apiUrl
 */
export function setKccConfigApiUrl(apiUrl) {
  // load config
  const envConfig = uiEnv[environment];

  // add kcc domain api url, make sure to user https://
  config.set({
    ...envConfig,
    apiDomainBaseURL: `https://${apiUrl}/`
  });
}
