import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setCurrentValueType, setMaxValueType, setOpenType } from "../types/uploadFileDialog";

export interface uploadFileDialogState {
  maxValue: number;
  currentValue: number;
  isOpen: boolean;
}

const initialState: uploadFileDialogState = {
  maxValue: 10,
  isOpen: false,
  currentValue: 0
};

export const uploadFileDialogSlice = createSlice({
  name: "uploadFileDialogState",
  initialState,
  reducers: {
    setUploadFileDialogMaxValue: (state, action: PayloadAction<setMaxValueType>) => {
      state.maxValue = action.payload.maxValue;
      // reset completed
      state.currentValue = 0;
    },
    setUploadFileDialogIsOpen: (state, action: PayloadAction<setOpenType>) => {
      state.isOpen = action.payload.isOpen;
    },
    setUploadFileDialogCurrentValue: (state, action: PayloadAction<setCurrentValueType>) => {
      state.currentValue = action.payload.currentValue;
    },
    addUploadFileDialogCompletedItem: (state) => {
      state.currentValue = state.currentValue + 1;
    }
  }
});

export const { setUploadFileDialogMaxValue, setUploadFileDialogIsOpen, setUploadFileDialogCurrentValue, addUploadFileDialogCompletedItem } =
  uploadFileDialogSlice.actions;

export default uploadFileDialogSlice.reducer;
