/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Domain API
 * API for admin domain
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  KCCConfigResponse,
  KccConfigControllerGetKccConfigBody
} from './ksApi.schemas'
import { customInstance } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';



export const kccConfigControllerGetKccConfig = (
    kccConfigControllerGetKccConfigBody: KccConfigControllerGetKccConfigBody,
 ) => {
      return customInstance<KCCConfigResponse>(
      {url: `/kcc-config`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: kccConfigControllerGetKccConfigBody
    },
      );
    }
  


export const getKccConfigControllerGetKccConfigMutationOptions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kccConfigControllerGetKccConfig>>, TError,{data: KccConfigControllerGetKccConfigBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof kccConfigControllerGetKccConfig>>, TError,{data: KccConfigControllerGetKccConfigBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof kccConfigControllerGetKccConfig>>, {data: KccConfigControllerGetKccConfigBody}> = (props) => {
          const {data} = props ?? {};

          return  kccConfigControllerGetKccConfig(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type KccConfigControllerGetKccConfigMutationResult = NonNullable<Awaited<ReturnType<typeof kccConfigControllerGetKccConfig>>>
    export type KccConfigControllerGetKccConfigMutationBody = KccConfigControllerGetKccConfigBody
    export type KccConfigControllerGetKccConfigMutationError = ErrorType<unknown>

    export const useKccConfigControllerGetKccConfig = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof kccConfigControllerGetKccConfig>>, TError,{data: KccConfigControllerGetKccConfigBody}, TContext>, }
) => {
    
      const mutationOptions = getKccConfigControllerGetKccConfigMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    