import React, { FunctionComponent, useState } from "react";

import styled from "styled-components";
import PropTypes from "prop-types";
import { InputType } from "reactstrap/es/Input";
import TextInput from "./TextInput";

export type NumberInputProps = {
  name?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  placeholder?: string;
  value?: string;
  error?: string;
  required?: boolean;
  type?: InputType;
  maxLength?: number;
  style?: React.CSSProperties;
  min?: number;
};

/**
 * Component that handles the <Input> from reactstrap with some necessary validations
 * necessary attributes for the props
 * placeholder= the input placeholder (string)
 * maxLength= how many numbers are required for the input (int)
 */
const NumberInput: FunctionComponent<NumberInputProps> = ({
  name,
  min = 0,
  label,
  maxLength,
  style,
  error,
  value,
  onChange,
  type = "text",
  ...props
}) => {
  const formatInput = (e: any) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    maxLengthCheck(e);
    if (parseInt(e.target.value) < min) {
      e.target.value = min.toString();
    }
    if (onChange) {
      onChange(e);
    }
  };

  const errors = false;

  return (
    <TextInput
      name="numInput"
      type="number"
      error={error}
      value={value}
      onChange={onChangeInternal}
      onKeyPress={formatInput}
      placeholder={props.placeholder}
      maxLength={maxLength}
      {...props}
    />
  );
};

const TokenInput = styled.input`
  width: 100%;
  font-size: 30px;
  color: black;
  text-align: center;
`;

export default NumberInput;
