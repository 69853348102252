/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Domain API
 * API for admin domain
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  LoginSuccess,
  LoginFailure,
  AuthControllerLogin403,
  AuthControllerLoginBody,
  RefreshTokenSuccess,
  RefreshTokenFailure
} from './ksApi.schemas'
import { customInstance } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';



/**
 * Response codes: 

    200: Indicates that the request has succeeded. 

    401: Indicates that the credentials provided are wrong. 

    403: Indicates that 2 factor authentication is required to login. 

 */
export const authControllerLogin = (
    authControllerLoginBody: AuthControllerLoginBody,
 ) => {
      return customInstance<LoginSuccess>(
      {url: `/auth/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: authControllerLoginBody
    },
      );
    }
  


export const getAuthControllerLoginMutationOptions = <TError = ErrorType<LoginFailure | AuthControllerLogin403>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerLogin>>, TError,{data: AuthControllerLoginBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authControllerLogin>>, TError,{data: AuthControllerLoginBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerLogin>>, {data: AuthControllerLoginBody}> = (props) => {
          const {data} = props ?? {};

          return  authControllerLogin(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AuthControllerLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerLogin>>>
    export type AuthControllerLoginMutationBody = AuthControllerLoginBody
    export type AuthControllerLoginMutationError = ErrorType<LoginFailure | AuthControllerLogin403>

    export const useAuthControllerLogin = <TError = ErrorType<LoginFailure | AuthControllerLogin403>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerLogin>>, TError,{data: AuthControllerLoginBody}, TContext>, }
) => {
    
      const mutationOptions = getAuthControllerLoginMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    export const authControllerRefreshToken = (
    token: string,
 ) => {
      return customInstance<RefreshTokenSuccess>(
      {url: `/auth/refreshToken/${token}`, method: 'post'
    },
      );
    }
  


export const getAuthControllerRefreshTokenMutationOptions = <TError = ErrorType<RefreshTokenFailure>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshToken>>, TError,{token: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshToken>>, TError,{token: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof authControllerRefreshToken>>, {token: string}> = (props) => {
          const {token} = props ?? {};

          return  authControllerRefreshToken(token,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AuthControllerRefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof authControllerRefreshToken>>>
    
    export type AuthControllerRefreshTokenMutationError = ErrorType<RefreshTokenFailure>

    export const useAuthControllerRefreshToken = <TError = ErrorType<RefreshTokenFailure>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof authControllerRefreshToken>>, TError,{token: string}, TContext>, }
) => {
    
      const mutationOptions = getAuthControllerRefreshTokenMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    