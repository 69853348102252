import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false
};

const layoutSlice = createSlice({
  name: "layoutState",
  initialState,
  reducers: {
    setLayout: (state, action) => {
      Object.assign(state, action.payload);
    }
  }
});

export const { setLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
