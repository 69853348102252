import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

export interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  // check if the user has a token, if they do they are logged in
  const authed = localStorage.getItem("token") !== null;
  if (!authed) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
