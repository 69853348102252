import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";
import { updateSelectedMachineType } from "../types/machineImageSelectorFormCompTypes";

export interface machineImageSelectorFormCompState {
  selectedMachine: string;
  error: string;
}

const initialState: machineImageSelectorFormCompState = {
  selectedMachine: "",
  error: ""
};
export const machineImageSelectorFormCompStateSlice = createSlice({
  name: "machineImageSelectorFormCompState",
  initialState,
  reducers: {
    resetSelectedMachine: (state) => {
      state.selectedMachine = "";
      state.error = "";
    },
    updateSelectedMachine: (state, action: PayloadAction<updateSelectedMachineType>) => {
      state.selectedMachine = action.payload.selectedMachine;
    },
    setSelectedMachineError: (state, action: PayloadAction<updateStringValueType>) => {
      state.error = action.payload.value;
    }
  }
});

export const { resetSelectedMachine, updateSelectedMachine, setSelectedMachineError } = machineImageSelectorFormCompStateSlice.actions;

export default machineImageSelectorFormCompStateSlice.reducer;
