import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateStringValueType } from "../types/genericPayloadTypes";

export interface softwareAssignmentConfirmationCompState {
  selectedSoftwareId: string | undefined;
  selectedOrgId: string | undefined;
}

const initialState: softwareAssignmentConfirmationCompState = {
  selectedSoftwareId: undefined,
  selectedOrgId: undefined
};
export const softwareAssignmentConfirmationCompStateSlice = createSlice({
  name: "softwareAssignmentConfirmationCompState",
  initialState,
  reducers: {
    softwareAssignmentConfirmationCompReset: (state) => {
      state.selectedSoftwareId = undefined;
      state.selectedOrgId = undefined;
    },
    softwareAssignmentSetSoftwareId: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedSoftwareId = action.payload.value;
    },
    softwareAssignmentSetOrgId: (state, action: PayloadAction<updateStringValueType>) => {
      state.selectedOrgId = action.payload.value;
    }
  }
});

export const { softwareAssignmentConfirmationCompReset, softwareAssignmentSetSoftwareId, softwareAssignmentSetOrgId } =
  softwareAssignmentConfirmationCompStateSlice.actions;

export default softwareAssignmentConfirmationCompStateSlice.reducer;
