
// Config file generated for KaleidoSim
export type UiConfigType = {
    [key: string]: {
      apiDomainBaseURL: string;
      tableConfig: {
        maxPageLimiter: number; // upper limit for the pagination component
      };
    };
  };

export const uiEnv: UiConfigType = {"kiproduction":{"apiDomainBaseURL":"https://geodict.apida.kaleidosim.com/","tableConfig":{"maxPageLimiter":10}}}

