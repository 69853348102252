import React from "react";

// import "react-app-polyfill/stable";
import "core-js";
import "./index.css";
import "./config/config";

import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";

import { store } from "./redux/reducerConfig";
import { AuthProvider } from "./appBase/auth.context";
import { default as DialogProvider } from "react-async-dialog";
import App from "./App";
import reportWebVitals from "./appBase/reportWebVitals";

const queryClient = new QueryClient();

const container = document.getElementById("root");

// @ts-ignore
createRoot(container).render(
  <Provider store={store}>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <DialogProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </DialogProvider>
      </QueryClientProvider>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
