import { REFRESH_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from "../../constants/localStorage";
import { store } from "../../redux/reducerConfig";
import { updateToken } from "../../redux/reducers/userLoginStateReducer";
import { AXIOS_INSTANCE } from "./custom-instance";

/**
 * Refresh token request
 * @returns
 */
export async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  return AXIOS_INSTANCE.post(`/auth/refreshToken/${refreshToken}`);
}

/**
 * Add an interceptor to validate if the token is expired
 * Then it will send a new request to get a new token
 * using the refresh token value
 * If the refresh token fails the user will be redirected
 * to the login page
 */
function addRefreshTokenInterceptor() {
  // adding interceptor
  AXIOS_INSTANCE.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;

      if (status === 401 && error.response.data.error.message === "jwt expired") {
        const originalRequest = error.config;

        return refreshToken()
          .then((response) => {
            store.dispatch(updateToken({ token: response.data.token }));
            localStorage.setItem(TOKEN_STORAGE_KEY, response.data.token);

            // eslint-disable-next-line no-param-reassign
            originalRequest.headers.Authorization = `Bearer ${response.data.token}`;

            return AXIOS_INSTANCE.request(originalRequest);
          })
          .catch((errors) => {
            localStorage.removeItem(TOKEN_STORAGE_KEY);
            store.dispatch(updateToken({ token: "" }));
            localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
            window.location.href = "/login";
          });
      }

      return Promise.reject(error);
    }
  );
}

export default addRefreshTokenInterceptor;
