/* eslint-disable no-shadow */
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card, CardGroup, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
// @ts-ignore
import logo from "../../assets/img/brand/blue_horizontal.png";
import LoginForm from "./LoginForm";
//import { REFRESH_TOKEN_STORAGE_KEY, TOKEN_STORAGE_KEY } from "../../constants/localStorage";
//import { TWO_FA_CHECK } from "./EnableTwoFactor";

import { authControllerLogin } from "../../api/endpoints/auth-controller";
import { AuthControllerLoginBody } from "../../api/endpoints/ksApi.schemas";
import { REFRESH_TOKEN_STORAGE_KEY, ROLE_LEVEL_STORAGE_KEY, TOKEN_STORAGE_KEY } from "../../constants/localStorage";
import { useAppDispatch } from "../../redux/reducerConfig";
import { AxiosError } from "axios";
import { useHydrate } from "react-query";
import routeConstants from "../../appBase/routeConstants";
import { saveCreds, updateToken } from "../../redux/reducers/userLoginStateReducer";

type LoginArgs = {
  email: string;
  password: string;
};

type ErrorMessage = {
  message: string;
};

/**
 * Login component, receives the email and password and calls a graphql mutation
 * @param props
 * @returns
 */
const LoginPage = (props: RouteComponentProps) => {
  const history = useHistory();
  const [errorSignin, setErrorSignin] = useState<ErrorMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [modal, showAuthModal] = useState(false);

  const appDispatch = useAppDispatch();

  const toggleModal = () => {
    showAuthModal(true);
  };

  const handleSubmit = async (ev: React.FormEvent<HTMLInputElement>, args: LoginArgs) => {
    setLoading(true);
    ev.preventDefault();

    try {
      const loginData: AuthControllerLoginBody = { email: args.email, password: args.password };
      const resp = await authControllerLogin(loginData);

      console.log(`Checking response`);

      if (resp.token != null && resp.refreshToken != null && resp.roleLevel != null) {
        setLoading(false);
        localStorage.setItem(TOKEN_STORAGE_KEY, resp.token);
        localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, resp.refreshToken);
        localStorage.setItem(ROLE_LEVEL_STORAGE_KEY, resp.roleLevel.toString());

        appDispatch(updateToken({ token: resp.token }));

        props.history.push(routeConstants.DashBoard);
      } else {
        throw new Error("Incorrect login");
      }
    } catch (err) {
      const error = err as AxiosError;
      setLoading(false);
      if (error.response?.status == 403) {
        // save the login data for 2FA login
        appDispatch(saveCreds({ email: args.email, password: args.password }));
        history.push(routeConstants.Login2FaPage);
      } else {
        // eslint-disable-next-line no-console
        console.error(err);
        setErrorSignin([{ message: "Something went wrong. Please try again." }]);
      }
    }
  };

  return (
    <>
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center mb-3">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <KSLogo className="mx-auto d-block" src={logo} alt="Logo" />
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <LoginForm loading={loading} handleSubmit={handleSubmit} errors={errorSignin} />
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const KSLogo = styled.img`
  width: 95%;
`;

export default LoginPage;
