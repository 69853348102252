import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

const NotFound404 = () => {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">404</h1>
              <h4 className="pt-3">That's an error.</h4>
              <p className="text-muted float-left">The page you are looking for was not found.</p>
            </div>
            <Link to="/">
              <Button color="primary" className="mt-3" active tabIndex={-1}>
                Go Back to the dashboard
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound404;
